import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';

const menuStyle = {
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'space-between',
};
const logoStyle = {
  fontSize: 20,
  marginLeft: '1vw',
  marginRight: 'auto',
};

function getItem(label, key, style, icon, className, children, type) {
  return {
    label,
    key,
    icon,
    children,
    type,
    style,
    className,
  };
}

const items = [
  getItem('स्वस्थ पोषण', '', logoStyle, undefined, 'logo'),
  getItem('लेख', 'article'),
  getItem('हमारे बारे में', 'about'),
  getItem('संपर्क', 'contacts'),
];

function HeaderC() {
  const [current, setCurrent] = useState('/');

  useEffect(() => {
    if (current.length > 2) {
      document.getElementById(current).scrollIntoView();
    }
  }, [current]);

  const onClick = (e) => {
    setCurrent(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={[...items]}
      style={menuStyle}
    />
  );
}

export default HeaderC;
