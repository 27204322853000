import React from 'react';
import { List } from 'antd';

const items = [
  {
    img: './img/1.jpg',
    title: 'परिचय',
    text: 'एक स्वस्थ आहार एक लंबे और सक्रिय जीवन की कुंजी है। पुरुषों के लिए, उचित पोषण विशेष रूप से महत्वपूर्ण है, क्योंकि यह शारीरिक फिटनेस, मस्तिष्क समारोह, सामान्य कल्याण और यहां तक कि भावनात्मक जीवन की गुणवत्ता को भी प्रभावित करता है। स्वस्थ भोजन की मूल बातें समझने से आपको अपने दैनिक जीवन में सही निर्णय लेने में मदद मिलेगी।',
  },
  {
    img: './img/2.jpg',
    title: 'प्रोटीन',
    text: 'प्रोटीन हमारी मांसपेशियों के लिए मुख्य निर्माण खंड हैं। पुरुषों को मांस, मछली, अंडे और फलियों का सेवन करने की सलाह दी जाती है। ऊतक की मरम्मत, ऊर्जा के स्तर को बनाए रखने और स्थिर मनोदशा सुनिश्चित करने के लिए प्रोटीन आवश्यक है।',
  },
  {
    img: './img/3.jpg',
    title: 'जटिल कार्बोहाइड्रेट',
    text: 'सब्जियों, साबुत अनाज और अनाज जैसे जटिल कार्बोहाइड्रेट को प्राथमिकता दें। वे लंबे समय तक चलने वाली ऊर्जा संतृप्ति प्रदान करते हैं, रक्त शर्करा के स्तर को बनाए रखते हैं, और अच्छे पाचन को बढ़ावा देते हैं।',
  },
  {
    img: './img/4.jpg',
    title: 'वसा',
    text: 'सभी वसा हानिकारक नहीं हैं। वनस्पति तेल, नट्स और मछली से असंतृप्त वसा का सेवन करना आवश्यक है। वे हृदय की रक्षा करने, हार्मोनल संतुलन बनाए रखने और त्वचा के स्वास्थ्य में सुधार करने में मदद करते हैं।',
  },
  {
    img: './img/5.jpg',
    title: 'विटामिन और खनिज',
    text: 'फल, सब्जियां और साग विटामिन का सबसे अच्छा स्रोत हैं। डेयरी उत्पादों से कैल्शियम के बारे में मत भूलना। यह हड्डी के स्वास्थ्य, ऑस्टियोपोरोसिस की रोकथाम, और एक सामान्य हृदय ताल के रखरखाव के लिए महत्वपूर्ण है।',
  },
  {
    img: './img/6.jpg',
    title: 'पानी',
    text: 'पर्याप्त पानी प्राप्त करना कल्याण की कुंजी है। प्रति दिन कम से कम 2 लीटर पीने की सिफारिश की जाती है। पानी पूरे शरीर में पोषक तत्वों के परिवहन में मदद करता है, शरीर के तापमान को नियंत्रित करता है, और स्वस्थ त्वचा को बनाए रखता है।',
  },
  {
    img: './img/7.jpg',
    title: 'शराब और निकोटीन का उन्मूलन',
    text: 'मध्यम शराब की खपत और धूम्रपान समाप्ति स्वास्थ्य और दीर्घायु को बढ़ावा देती है। शराब और निकोटीन विभिन्न बीमारियों और समग्र जीवन प्रत्याशा में कमी का कारण बन सकते हैं।',
  },
  {
    img: './img/8.jpg',
    title: 'नियमित भोजन',
    text: 'नियमित रूप से खाने की कोशिश करें, भोजन के बीच ओवरईटिंग और लंबे ब्रेक से बचें। भोजन का उचित वितरण एक इष्टतम चयापचय को बनाए रखने में मदद करता है।',
  },
  {
    img: './img/9.jpg',
    title: 'शारीरिक गतिविधि',
    text: 'एक स्वस्थ जीवन शैली में न केवल उचित पोषण, बल्कि नियमित व्यायाम भी शामिल है। शारीरिक गतिविधि दिल, फेफड़ों और मनोदशा को मजबूत करने में मदद करती है।',
  },
  {
    img: './img/10.jpg',
    title: 'मादक पेय और उनके प्रभाव',
    text: 'जबकि कई पुरुष समय-समय पर एक गिलास शराब या बीयर में लिप्त होते हैं, अत्यधिक शराब की खपत के स्वास्थ्य प्रभावों के प्रति सावधान रहना महत्वपूर्ण है। इससे यकृत, हृदय और अन्य अंगों के साथ समस्याएं हो सकती हैं।',
  },
  {
    img: './img/11.jpg',
    title: 'नींद और पोषण',
    text: 'गुणवत्ता नींद शरीर की वसूली प्रक्रिया में महत्वपूर्ण भूमिका निभाती है। एक स्वस्थ आहार अनिद्रा जैसे नींद विकारों को रोककर बेहतर नींद को बढ़ावा दे सकता है। नट्स और अनाज में पाए जाने वाले मैग्नीशियम जैसे खनिज, नींद की गुणवत्ता में सुधार कर सकते हैं।',
  },
  {
    img: './img/12.jpg',
    title: 'तनाव और पोषण',
    text: 'तनाव भूख और भोजन विकल्पों को प्रभावित कर सकता है। तनाव के प्रभाव में, बहुत से लोग अधिक खाने और कम स्वस्थ खाद्य पदार्थों का चयन करने के लिए प्रवण होते हैं। हालांकि, उचित पोषण मस्तिष्क के लिए आवश्यक पोषक तत्व प्रदान करके तनाव के स्तर को नियंत्रित करने में मदद कर सकता है।',
  },
  {
    img: './img/13.jpg',
    title: 'समाप्ति',
    text: 'आपके खाने की आदतों का आपके समग्र स्वास्थ्य और कल्याण पर सीधा प्रभाव पड़ता है। पूरे और प्राकृतिक खाद्य पदार्थों का चयन करके, आप अपनी भलाई, ऊर्जा और समग्र जीवन प्रत्याशा में सुधार कर सकते हैं। आज ही अपनी स्वस्थ भोजन यात्रा शुरू करें!',
  },
];

const ArticleC = () => (
  <List
    id="article"
    className="article"
    size="large"
    header={<h2>पुरुषों के लिए स्वस्थ भोजन</h2>}
    // footer={<div>Footer</div>}
    // bordered
    dataSource={items}
    renderItem={(item, indx) => (
      <List.Item style={{ display: 'flex' }}>
        <img
          className={indx % 2 === 0 ? 'articleImgOdd' : ''}
          src={item.img}
          alt=""
          style={{
            margin: '0 10px',
            width: '30%',
            float: 'left',
          }}
        />
        <div style={{ textAlign: 'left' }}>
          <h3>{item.title}</h3>
          {item.text}
          <p style={{ textAlign: 'left' }}></p>
        </div>
      </List.Item>
    )}
  />
);

export default ArticleC;
