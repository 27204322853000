import React from 'react';
import { MailFilled, PhoneFilled, PushpinFilled } from '@ant-design/icons';
import './ContactsC.css';
import { Avatar, List } from 'antd';

const data = [
  {
    title: '+91 02224383643',
    icon: <PhoneFilled style={{ fontSize: 25 }} />,
  },
  {
    title:
      'भारत, महाराष्ट्र, मुंबई, 22 ए, महाकाली गुफाओं आर, नंद किशोर इंडल एस्टेट, एनआर पेपर बॉक्स, अंधेरी (पश्चिम)',
    icon: <PushpinFilled style={{ fontSize: 25 }} />,
  },
];

const ContactsC = () => (
  <List
    id="contacts"
    header={<h2>संपर्क</h2>}
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item, index) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar src={item.icon} style={{ color: '#4f4f4f' }} />}
          title={<a href="">{item.title}</a>}
          className="contact"
        />
      </List.Item>
    )}
  />
);
export default ContactsC;
