import React from 'react';
import { Carousel } from 'antd';

const contentStyle = {
  margin: 0,
  height: 'auto',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
};

const items = [
  { img: './img/4.jpg' },
  { img: './img/2.jpg' },
  { img: './img/8.jpg' },
];

const CarouselC = () => {
  return (
    <Carousel autoplay>
      {items.map((item) => (
        <img src={item.img} alt="" />
      ))}
    </Carousel>
  );
};

export default CarouselC;
