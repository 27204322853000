import { Layout, Space } from 'antd';
import './App.css';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import HeaderC from './components/Header/HeaderC';
import CarouselC from './components/CarouselC/CarouselC';
import FooterC from './components/Footer/FooterC';
import ContactsC from './components/ContactsC/ContactsC';
import QuestionsC from './components/QuestionsC/QuestionsC';
import AboutUsC from './components/AboutUsC/AboutUsC';
import CardsC from './components/ListC/ListC';
import ArticleC from './components/ArticleC/ArticleC';

const headerStyle = {
  color: '#000',
  paddingInline: 20,
  lineHeight: '64px',
  backgroundColor: '#f5f5f5',
  width: '90%',
  maxWidth: 1200,
  margin: '0 auto',
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  width: '90%',
  maxWidth: 1200,
  margin: '0 auto',
};
const footerStyle = {
  textAlign: 'center',
  width: '90%',
  maxWidth: 1200,
  margin: '0 auto',
};

function App() {
  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
      }}
      size={[0, 48]}
    >
      <Layout>
        <Header style={headerStyle}>
          <HeaderC />
        </Header>
        <Content style={contentStyle}>
          <CarouselC />
          <ArticleC />
          <AboutUsC />
          <ContactsC />
        </Content>
        <Footer style={footerStyle}>
          <FooterC />
        </Footer>
      </Layout>
    </Space>
  );
}

export default App;
