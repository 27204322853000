import React from 'react';
import { Menu } from 'antd';

const menuStyle = {
  backgroundColor: 'transparent',
};
const logoStyle = {
  fontSize: 20,
  marginLeft: '1vw',
  marginRight: 'auto',
};

function getItem(label, key, style, icon, className, children, type) {
  return {
    label,
    key,
    icon,
    children,
    type,
    style,
    className,
  };
}

const items = [
  getItem('स्वस्थ पोषण', '', logoStyle, undefined, 'logo'),
  getItem('लेख', 'article'),
  getItem('हमारे बारे में', 'about'),
  getItem('संपर्क', 'contacts'),
];

const onClick = (e) => {
  document.getElementById(e.key).scrollIntoView();
};

function FooterC() {
  return (
    <Menu
      mode="vertical"
      onClick={onClick}
      items={[...items]}
      style={menuStyle}
      selectable={false}
    />
  );
}

export default FooterC;
