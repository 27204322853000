import React from 'react';
import { List } from 'antd';

const items = [
  {
    text: 'एक ऐसी दुनिया में जहां तनाव का स्तर और जीवन की गति बढ़ रही है, उचित पोषण स्वास्थ्य और जीवन की गुणवत्ता को बनाए रखने में एक महत्वपूर्ण कारक बन रहा है। कंपनी "स्वस्थ पोषण" बस यही करती है: यह स्वस्थ पोषण के क्षेत्र में पुरुषों को परामर्श सेवाएं प्रदान करती है।',
  },
  {
    title: 'कंपनी का मिशन:',
    text: 'हमारा लक्ष्य हर आदमी को अपने आहार में सद्भाव और संतुलन खोजने में मदद करना है, पुरुष शरीर की विशेषताओं, उसकी जरूरतों और व्यक्तिगत विशेषताओं को ध्यान में रखते हुए।',
  },
  {
    title: 'व्यक्तिगत दृष्टिकोण:',
    text: '"स्वस्थ भोजन" समझता है कि प्रत्येक व्यक्ति अद्वितीय है। इसलिए, हम ग्राहक के स्वास्थ्य, जीवन शैली और वरीयताओं के विश्लेषण के आधार पर व्यक्तिगत भोजन योजनाएं विकसित करते हैं।',
  },
  {
    title: 'व्यावसायिकता:',
    text: 'हमारी टीम में योग्य आहार विशेषज्ञ, पोषण विशेषज्ञ और फिटनेस प्रशिक्षक शामिल हैं जो व्यापक पोषण सहायता प्रदान करने के लिए तैयार हैं।',
  },
  {
    title: 'शैक्षिक कार्यक्रम:',
    text: 'परामर्श के अलावा, हम उचित पोषण के महत्व की आपकी समझ का विस्तार करने के लिए स्वस्थ भोजन के विषय पर शैक्षिक सेमिनार और वेबिनार प्रदान करते हैं।',
  },
  {
    title: 'अत्याधुनिक तकनीकें:',
    text: 'स्वस्थ भोजन प्रभावी और संतुलित भोजन योजना बनाने के लिए नवीनतम वैज्ञानिक अनुसंधान का उपयोग करता है।',
  },
  {
    title: 'भागीदारी:',
    text: 'कंपनी अपने ग्राहकों के लिए व्यापक कार्यक्रम बनाने के लिए सर्वश्रेष्ठ स्वास्थ्य क्लबों, चिकित्सा संस्थानों और स्वास्थ्य खाद्य निर्माताओं के साथ सक्रिय रूप से सहयोग करती है।',
  },
  {
    title: '24/7 समर्थन:',
    text: 'हमारे ग्राहक चल रहे ऑनलाइन समर्थन पर भरोसा कर सकते हैं, चाहे वह पोषण के बारे में प्रश्न हो या प्रेरणा की आवश्यकता हो।',
  },
  {
    title: 'समुदाय:',
    text: 'हमने समान विचारधारा वाले लोगों का एक समुदाय बनाया है जहां हर कोई अपने अनुभव साझा कर सकता है, सवाल पूछ सकता है और सलाह ले सकता है।',
  },
  {
    text: '"स्वस्थ पोषण" सिर्फ एक परामर्श कंपनी नहीं है। यह एक स्वस्थ और संतुलित जीवन शैली के रास्ते पर आपका विश्वसनीय साथी है। हम मानते हैं कि उचित पोषण आपके भविष्य में एक निवेश है, और हम आपकी मदद करने के लिए तैयार हैं।',
  },
];

const AboutUsC = () => (
  <List
    id="about"
    className="article"
    size="large"
    header={
      <h2 className="header">
        स्वस्थ पोषण कंपनी: पुरुषों के लिए स्वस्थ पोषण सलाह
      </h2>
    }
    // footer={<div>Footer</div>}
    // bordered
    dataSource={items}
    renderItem={(item) => (
      <List.Item
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
        }}
      >
        {item.title && <h3>{item.title}</h3>}
        <p style={{ textAlign: 'left' }}>{item.text}</p>
      </List.Item>
    )}
  />
);

export default AboutUsC;
