import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ConfigProvider, theme } from 'antd';
import styles from './styles/appStyles';

// default algorithm theme.defaultAlgorithm
// dark algorithm theme.darkAlgorithm
// compact algorithm theme.compactAlgorithm
// import { theme } from 'antd';
// theme={{
//   algorithm: theme.darkAlgorithm,
// }}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider theme={styles}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
);
